import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';

ChartJS.register(
    ArcElement,
    Legend,
    Tooltip
);

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const formatPrice = (priceIn) => {
    let złotówki = Math.floor(priceIn / 100);
    let grosze = priceIn % 100;

    let złotówkiZSpacjami = złotówki.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return złotówkiZSpacjami + (grosze !== 0 ? '.' + (grosze < 10 ? '0' : '') + grosze : '.00');
}

const ProjectedChart = ({ projects, chartjsSize, loadingChart, totalInvestment }) => {

    const chartData = {
        datasets: [
            {
                data: projects.map(el => {
                    return el
                }),
                backgroundColor: ["rgb(11, 182, 173)", "rgb(116, 96, 238)"],
                borderWidth: 1,
                hoverBorderColor: ["rgb(11, 182, 173)", "rgb(116, 96, 238)"],
                hoverBorderWidth: 8,
                offset: 10,
                borderRadius: 5,
                cutout: 52,
            }
        ]
    }

    const chartDataZero = {
        datasets: [
            {
                data: [100],
                backgroundColor: ["rgb(11, 182, 173)"],
                borderWidth: 0,
            }
        ]
    }

    let doughnutLabel = {
        id: 'doughnutLabel',
        afterDatasetsDraw(chart) {
            let centerX
            let centerY
            const { ctx } = chart;
            if (chart.getDatasetMeta(0).data[0] !== undefined) {
                centerX = chart.getDatasetMeta(0).data[0].x
                centerY = chart.getDatasetMeta(0).data[0].y

                ctx.font = 'bolder 12px sans-serif'
                ctx.fillStyle = '#000'
                ctx.textAlign = 'center'
                ctx.textBaseLine = 'middle'
                if (chart._active.length > 0) {
                    if (chart.config._config.data.datasets[0].data[chart._active[0].index] === projects[0]) {
                        ctx.fillText(`Zysk całkowity`, centerX, centerY - 6)
                    } else {
                        ctx.font = 'bolder 10px sans-serif'
                        ctx.fillText(`Zysk prognozowany`, centerX, centerY - 6)
                    }
                    ctx.fillText(`${formatPrice(chart.config._config.data.datasets[0].data[chart._active[0].index])} zł`, centerX, centerY + 14)

                } else {
                    ctx.fillText(`Zysk całkowity`, centerX, centerY - 6)
                    ctx.fillText(`${formatPrice(projects[0])} zł`, centerX, centerY + 14)
                }
                ctx.save()
            }
        }
    }

    let doughnutLabelZero = {
        id: 'doughnutLabel',
        afterDatasetsDraw(chart) {
            let centerX
            let centerY
            const { ctx } = chart;
            if (chart.getDatasetMeta(0).data[0] !== undefined) {
                centerX = chart.getDatasetMeta(0).data[0].x
                centerY = chart.getDatasetMeta(0).data[0].y
                ctx.font = 'bolder 20px sans-serif'
                ctx.fillStyle = '#000'
                ctx.textAlign = 'center'
                ctx.textBaseLine = 'middle'
                ctx.fillText(`0zł`, centerX, centerY)
                ctx.save()
            }
        }
    }

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: false
            },
            datalabels: {
                display: false,
            },
        }
    }

    useEffect(() => {
    }, [projects])

    return (
        <>
            {
                loadingChart
                    ?
                    <CircularProgress size={100} color="secondary" />
                    :
                    totalInvestment > 0
                        ?
                        <Doughnut
                            data={chartData}
                            options={options}
                            plugins={[doughnutLabel]}
                            style={chartjsSize}
                        />
                        :
                        <Doughnut
                            data={chartDataZero}
                            options={options}
                            plugins={[doughnutLabelZero]}
                            style={chartjsSize}
                        />
            }
        </>
    )
}

export default ProjectedChart