import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Alert, Avatar, Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Snackbar, Switch, Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import axios from 'axios'

const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (isNaN(date)) {
        return null
    } else {
        return [year, month, day].join('-');
    }
}

const countries = [
    'Niemcy',
    'Szwajcaria',
    'Kanada',
    'Grecja',
    'Filipiny',
    'Singapur',
    'Austria',
    'Portugalia',
    'Belgia',
    'Australia',
    'Ukraina',
    'Irlandia',
    'Hiszpania',
    'Holandia',
    'Finlandia',
    'Włochy',
    'Chiny',
    'Japonia',
    'Polska',
    'Brazylia',
    'Nowa Zelandia',
    'Korea',
    'Luksemburg',
    'Malezja',
    'Francja',
    'Czechy',
    'Szwecja',
    'Rosja',
    'Meksyk',
    'Wielka Brytania',
    'Bułgaria',
    'Rumunia',
    'Dania',
    'Tajwan',
    'Maroko'
]

const EditUser = ({
    VisuallyHiddenInput,
}) => {

    const nameAndSurnameRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()
    const streetRef = useRef()
    const avatarRef = useRef()
    const peselRef = useRef()
    const passportRef = useRef()
    const idNumberRef = useRef()
    const postCodeRef = useRef()
    const cityRef = useRef()
    const NIPRef = useRef()
    const KRSRef = useRef()
    const REGONRef = useRef()
    const contactEmailRef = useRef()
    const contactPersonRef = useRef()
    const companyNameRef = useRef()
    const contactPhoneRef = useRef()
    const contactStreetRef = useRef()
    const contactCityRef = useRef()
    const contactPostCodeRef = useRef()
    const consentToTermsRef = useRef()
    const consentToRodoRef = useRef()
    const consentToMarketingRef = useRef()
    const consentToTelemarketingRef = useRef()

    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = openSnackbar;
    const [fileName, setFileName] = useState("")
    const [isIdType, setIsIdType] = useState(false)
    const [isCompany, setIsCompany] = useState(false)
    const [validatorCustomRodo, setValidatorCustomRodo] = useState(false);
    const [uploadImage, setUploadImage] = useState(null)
    const [imageToSend, setImageToSend] = useState(null)
    const [errorAvatar, setErrorAvatar] = useState("")
    const [isPostCode, setIsPostCode] = useState(true)
    const [snackMessage, setSnackMessage] = useState("")

    const [userState, setUserState] = useState({
        user: {
            nameAndSurname: "",
            contactPerson: "",
            email: "",
            contactEmail: "",
            idExpired: "",
            phone: "",
            contactPhone: "",
            street: "",
            contactStreet: "",
            city: "",
            contactCity: "",
            postcode: "",
            contactPostCode: "",
            passport: "",
            country: "",
            pesel: "",
            idNumber: "",
            companyName: "",
            NIP: "",
            KRS: "",
            REGON: "",
            consent_to_terms: "",
            consent_to_rodo: "",
            consent_to_marketing: "",
            consent_to_telemarketing: "",
        },
        passwordConfirm: '',
    })
    const [alertState, setAlertState] = useState(false)
    const [errorCountry, setErrorCountry] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [idExpired, setIdExpired] = useState(dayjs(''))

    const handleClick = (event) => {
        avatarRef.current.click();
    };

    const handleFile = (file) => {
        setFileName(file.name);
    };

    const handleUpload = (e) => {
        const fileUploaded = e.target.files[0];
        handleFile(fileUploaded);
        setImageToSend(fileUploaded)
        const reader = new FileReader();
        reader.onloadend = () => {
            setUploadImage(reader.result);
        };
        reader.readAsDataURL(fileUploaded);
    }

    const handleDocumentType = () => {
        setIsIdType(!isIdType)
    }

    const handleCompany = () => {
        setIsCompany(!isCompany)
    }

    const setChange = () => {

        setUserState({
            ...userState,
            user: {
                ...userState.user,
                nameAndSurname: nameAndSurnameRef.current?.value,
                contactPerson: contactPersonRef.current?.value,
                email: emailRef.current?.value,
                contactEmail: contactEmailRef.current?.value,
                idExpired: idExpired,
                phone: phoneRef.current?.value,
                contactPhone: contactPhoneRef.current?.value,
                street: streetRef.current?.value,
                contactStreet: contactStreetRef.current?.value,
                city: cityRef.current?.value,
                contactCity: contactCityRef.current?.value,
                postcode: postCodeRef.current?.value,
                contactPostCode: contactPostCodeRef.current?.value,
                passport: passportRef.current?.value,
                country: selectedCountry,
                pesel: peselRef.current?.value,
                idNumber: idNumberRef.current?.value,
                companyName: companyNameRef.current?.value,
                NIP: NIPRef.current?.value,
                KRS: KRSRef.current?.value,
                REGON: REGONRef.current?.value,
                consent_to_terms: consentToTermsRef.current?.checked,
                consent_to_rodo: consentToRodoRef.current?.checked,
                consent_to_marketing: consentToMarketingRef.current?.checked,
                consent_to_telemarketing: consentToTelemarketingRef.current?.checked,
            }
        });

        console.log("dayjs: ", idExpired)
        handleValidate()
    }

    const handleValidate = () => {

        if (postCodeRef.current?.value.match(/^\d{2}-\d{3}$/)) {
            setIsPostCode(true)
        } else {
            setIsPostCode(false)
        }

        setValidatorCustomRodo(consentToRodoRef.current?.value);

        ValidatorForm.addValidationRule('isPesel', (value) => {
            if (value.match(/^(?:\d{11})$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isPhone', (value) => {
            if (value && value.match(/^\+?1?\d{9,15}$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isNIP', (value) => {
            if (value && value.match(/^\d{3}-\d{3}-\d{2}-\d{2}$|^\d{3}-\d{2}-\d{2}-\d{3}$|^\d{10}$/)) {
                return true;
            }
            return false;
        });
        ValidatorForm.addValidationRule('isKRS', (value) => {
            if (value && value.match(/^\d{9,14}$/)) {
                return true;
            }
            return false;
        });
        ValidatorForm.addValidationRule('isREGON', (value) => {
            if (value.length === 9) {
                return true;
            } else {
                return false
            }

        });
        ValidatorForm.addValidationRule('isIDNumber', (value) => {
            if (value.length === 9) {
                return true;
            } else {
                return false
            }

        });

        ValidatorForm.addValidationRule('isEmpty', (value) => {

            if (value && !value.match(/^\s*$/)) {
                return true;
            }
            return false;
        });
    }

    const handleCountryChange = (event) => {
        if (event.target.value === 0) {
            setErrorCountry(true)
        } else {
            setErrorCountry(false)
        }
        setSelectedCountry(event.target.value)
        setChange()
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("submit")
        setChange()
        if (uploadImage) {
            axios.post("/users/profile/update-avatar/", {
                "avatar": imageToSend
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                })
                .then(res => {
                    handleOpenSnackbar({ vertical: 'top', horizontal: 'center' }, "Avatar został zmieniony", "success")

                })
                .catch(err => {
                    Object.entries(err.response.data).forEach(function ([key, value]) {
                        handleOpenSnackbar({ vertical: 'top', horizontal: 'center' }, value, "error")
                    })
                    setErrorAvatar(err.response.data.avatar)
                })
        }
        axios.patch("/users/profile/update/", {
            "name": userState.user.nameAndSurname,
            "email": userState.user.email,
            "phone_number": userState.user.phone,
            "consent_to_marketing": userState.user.consent_to_marketing,
            "consent_to_telemarketing": userState.user.consent_to_telemarketing,
            "consent_to_terms": userState.user.consent_to_terms,
            "consent_to_rodo": userState.user.consent_to_rodo,
            "city": userState.user.city,
            "postcode": userState.user.postcode,
            "street": userState.user.street,
            "pesel": userState.user.pesel,
            "id_number": userState.user.idNumber,
            "id_expiration_date": formatDate(idExpired),
            "use_passport": isIdType,
            "passport_number": userState.user.passport,
            "passport_country": selectedCountry,
            "is_company": isCompany,
            "company_name": userState.user.companyName,
            "company_contact_person_fullname": userState.user.contactPerson,
            "company_contact_person_telephone": userState.user.contactPhone,
            "company_contact_person_email": userState.user.contactEmail,
            "company_vat_id": userState.user.NIP,
            "company_krs": userState.user.KRS,
            "company_regon": userState.user.REGON
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            }
        )
            .then(res => {
                handleOpenSnackbar({ vertical: 'top', horizontal: 'center' }, "Twoje dane zostały zaktualizowane", "success")

            })
            .catch(err => {
                Object.entries(err.response.data).forEach(function ([key, value]) {
                    handleOpenSnackbar({ vertical: 'top', horizontal: 'center' }, value, "error")
                })
            })
    };

    const handleOpenSnackbar = (newState, message, alert) => {
        setOpenSnackbar({ ...newState, open: true });
        setAlertState(alert)
        setSnackMessage(message)
    };

    const handleClose = () => {
        setOpenSnackbar({ ...openSnackbar, open: false });
    };

    useEffect(() => {
        axios("/users/profile/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setUserState({
                    ...userState,
                    user: {
                        ...userState.user,
                        nameAndSurname: res.data.name === null ? "" : res.data.name,
                        contactPerson: res.data.company_contact_person_fullname === null ? "" : res.data.company_contact_person_fullname,
                        email: res.data.email === null ? "" : res.data.email,
                        contactEmail: res.data.company_contact_person_email === null ? "" : res.data.company_contact_person_email,
                        phone: res.data.phone_number === null ? "" : res.data.phone_number,
                        contactPhone: res.data.company_contact_person_telephone === null ? "" : res.data.company_contact_person_telephone,
                        city: res.data.city === null ? "" : res.data.city,
                        contactCity: res.data.company_contact_city === null ? "" : res.data.company_contact_city,
                        postcode: res.data.postcode === null ? "" : res.data.postcode,
                        contactPostCode: res.data.company_contact_postcode === null ? "" : res.data.company_contact_postcode,
                        KRS: res.data.company_krs === null ? "" : res.data.company_krs,
                        NIP: res.data.company_vat_id === null ? "" : res.data.company_vat_id,
                        REGON: res.data.company_regon === null ? "" : res.data.company_regon,
                        street: res.data.street === null ? "" : res.data.street,
                        pesel: res.data.pesel === null ? "" : res.data.pesel,
                        idNumber: res.data.id_number === null ? "" : res.data.id_number,
                        contactStreet: res.data.company_contact_street === null ? "" : res.data.company_contact_street,
                        companyName: res.data.company_name === null ? "" : res.data.company_name,
                        consent_to_rodo: res.data.consent_to_rodo === undefined ? false : res.data.consent_to_rodo,
                        consent_to_terms: res.data.consent_to_terms === undefined ? false : res.data.consent_to_terms,
                        consent_to_telemarketing: res.data.consent_to_telemarketing === undefined ? false : res.data.consent_to_telemarketing,
                        consent_to_marketing: res.data.consent_to_marketing === undefined ? false : res.data.consent_to_marketing
                    }

                })
                setUploadImage(res.data.avatar.original)
                if (res.data.id_expiration_date === null) {
                    setIdExpired("")
                } else {
                    setIdExpired(res.data.id_expiration_date)
                }
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    return (
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <Box sx={{ width: 500 }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    key={vertical + horizontal}
                >
                    <Alert
                        onClose={handleClose}
                        severity={alertState}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextValidator
                        required
                        key="nameAndUsername"
                        margin="normal"
                        fullWidth
                        id="nameAndSurname"
                        label="Imię i Nazwisko"
                        name="nameAndSurname"
                        autoComplete='new-nameAndSurname'
                        onChange={setChange}
                        type="text"
                        inputRef={nameAndSurnameRef}
                        value={userState.user.nameAndSurname}
                    />
                    <TextValidator
                        required
                        key="email"
                        id="standard-email"
                        label="E-mail"
                        margin="normal"
                        autoComplete='new-email'
                        inputRef={emailRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.email}
                        validators={['isEmail']}
                        errorMessages={['Podaj poprawny adres e-mail.']}
                    />
                </Grid>
                <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={'column'}>
                    <Tooltip title="upload image">
                        <Avatar onClick={handleClick} src={uploadImage} alt="upload image" sx={{ width: "100px", height: "100px", cursor: "pointer", marginBottom: "16px" }} />
                    </Tooltip>
                    <VisuallyHiddenInput ref={avatarRef} type="file" accept="image/*" onChange={handleUpload} />
                    {errorAvatar && <FormHelperText sx={{ fontSize: "14px", fontWeight: "700" }} error>{errorAvatar}</FormHelperText>}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextValidator
                        style={{ marginBottom: "20px" }}
                        id="standard-phone"
                        label="Numer telefonu"
                        margin="normal"
                        autoComplete='new-phone'
                        inputRef={phoneRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.phone}
                    />
                    <TextValidator
                        style={{ marginBottom: "20px" }}
                        id="standard-pesel"
                        label="PESEL"
                        margin="normal"
                        inputRef={peselRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.pesel}
                    />
                    <FormControlLabel control={<Switch color="secondary" onChange={handleDocumentType} checked={isIdType} />} label="Użyję paszportu" />
                    {
                        isIdType
                            ?
                            <>
                                <TextValidator
                                    style={{ marginBottom: "20px" }}
                                    id="standard-passport"
                                    label="Numer Paszportu"
                                    margin="normal"
                                    inputRef={passportRef}
                                    fullWidth
                                    type='text'
                                    onChange={setChange}
                                    value={userState.user.passport}
                                />
                                <FormControl margin="normal" fullWidth error={errorCountry}>
                                    <InputLabel id="country-simple-select-error-label">Kraj</InputLabel>
                                    <Select
                                        labelId="country-simple-select-error-label"
                                        id="country-select-error"
                                        label="Kraj"
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                    >
                                        {countries.map((countries, index) => (
                                            <MenuItem key={index} value={countries}>
                                                {countries}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errorCountry && <FormHelperText>To pole jest wymagane!</FormHelperText>}
                                </FormControl>
                            </>
                            :
                            <>
                                <TextValidator
                                    style={{ marginBottom: "20px" }}
                                    id="standard-idnumber"
                                    label="Seria i numer dowodu osobistego"
                                    margin="normal"
                                    inputRef={idNumberRef}
                                    fullWidth
                                    type='text'
                                    onChange={setChange}
                                    value={userState.user.idNumber}
                                />
                                <Box paddingTop={2} width={'100%'}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            label="Data ważności dowodu osobistego"
                                            value={dayjs(idExpired)}
                                            onChange={newValue => {
                                                setIdExpired(newValue)
                                                setChange()
                                            }
                                            }
                                            sx={{ width: "100%" }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </>
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextValidator
                        style={{ marginBottom: "20px" }}
                        id="standard-street"
                        label="Ulica"
                        margin="normal"
                        inputRef={streetRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.street}
                        validators={['required']}
                        errorMessages={['To pole jest wymagane']}
                    />
                    <TextValidator
                        style={{ marginBottom: "20px" }}
                        id="standard-city"
                        label="Miasto"
                        margin="normal"
                        inputRef={cityRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.city}
                        validators={['required']}
                        errorMessages={['To pole jest wymagane']}
                    />
                    <TextValidator
                        required
                        style={{ marginBottom: "20px" }}
                        error={!isPostCode}
                        id="standard-zipcode"
                        label="Kod pocztowy"
                        margin="normal"
                        inputRef={postCodeRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.postcode}
                    />
                    {!isPostCode && <FormHelperText error>Proszę podać prawidłowy kod pocztowy </FormHelperText>}
                </Grid>
            </Grid>
            <FormControlLabel control={<Switch color="secondary" onChange={handleCompany} checked={isCompany} />} label="Konto firmowe" />
            {
                isCompany
                &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-company-name"
                                label="Nazwa firmy"
                                margin="normal"
                                inputRef={companyNameRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.companyName}
                                validators={['required']}
                                errorMessages={['To pole jest wymagane']}
                            />
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-NIP"
                                label="NIP"
                                margin="normal"
                                inputRef={NIPRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.NIP}
                                validators={['isNIP', 'required']}
                                errorMessages={['Proszę podać prawidłowy numer NIP', 'To pole jest wymagane']}
                            />
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-KRS"
                                label="KRS"
                                margin="normal"
                                inputRef={KRSRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.KRS}
                                validators={['isKRS', 'required']}
                                errorMessages={['Proszę podać prawidłowy KRS', 'To pole jest wymagane']}
                            />
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-REGON"
                                label="REGON"
                                margin="normal"
                                inputRef={REGONRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.REGON}
                                validators={['isREGON', 'required']}
                                errorMessages={['Proszę podać prawidłowy REGON', 'To pole jest wymagane']}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-contact-person"
                                label="Dane osoby kontaktowej"
                                margin="normal"
                                inputRef={contactPersonRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.contactPerson}
                                validators={['required']}
                                errorMessages={['To pole jest wymagane']}
                            />
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-contact-email"
                                label="Kontaktowy email"
                                margin="normal"
                                inputRef={contactEmailRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.contactEmail}
                                validators={['isEmail', 'required']}
                                errorMessages={['Proszę podać prawidłowy email', 'To pole jest wymagane']}
                            />
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-contact-phone"
                                label="Kontaktowy numer telefonu"
                                margin="normal"
                                inputRef={contactPhoneRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.contactPhone}
                                validators={['isPhone', 'required']}
                                errorMessages={['Proszę podać prawidłowy numer telefonu', 'To pole jest wymagane']}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-street"
                                label="Ulica"
                                margin="normal"
                                inputRef={contactStreetRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.contactStreet}
                                validators={['required']}
                                errorMessages={['To pole jest wymagane']}
                            />
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-city"
                                label="Miasto"
                                margin="normal"
                                inputRef={contactCityRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.contactCity}
                                validators={['required']}
                                errorMessages={['To pole jest wymagane']}
                            />
                            <TextValidator
                                style={{ marginBottom: "20px" }}
                                id="standard-zipcode"
                                label="Kod pocztowy"
                                margin="normal"
                                inputRef={contactPostCodeRef}
                                fullWidth
                                type='text'
                                onChange={setChange}
                                value={userState.user.contactPostCode}
                                validators={['isPostCodeCompany', 'required']}
                                errorMessages={['Proszę podać prawidłowy kod pocztowy', 'To pole jest wymagane']}
                            />
                        </Grid>
                    </Grid>
                </>
            }
            <FormControlLabel required control={<Switch color="secondary" onChange={setChange} inputRef={consentToRodoRef} checked={userState.user.consent_to_rodo} value={userState.user.consent_to_rodo} />} label="Wyrażam zgodę na przekazanie moich danych osobowych w postaci imienia (imion) i nazwiska, adresu zamieszkania, adresu e-mail, nr telefonu, serii i nr dowodu osobistego Pożyczkodawcy celem zawarcia Umowy pożyczki." />
            <FormControlLabel control={<Switch color="secondary" onChange={setChange} inputRef={consentToMarketingRef} checked={userState.user.consent_to_marketing} value={userState.user.consent_to_marketing} />} label="Wyrażam zgodę na otrzymywanie od Crowder.Pro newslettera i informacji handlowych za pośrednictwem środków komunikacji elektronicznej na podstawie artykułu 10 ustawy o świadczeniu usług drogą elektroniczną." />
            <FormControlLabel control={<Switch color="secondary" onChange={setChange} inputRef={consentToTelemarketingRef} checked={userState.user.consent_to_telemarketing} value={userState.user.consent_to_telemarketing} />} label="Wyrażam zgodę na wykorzystywanie przez Crowder.Pro telekomunikacyjnych i automatycznych systemów wywołujących w celu marketingu bezpośredniego produktów oferowanych przez Crowder.Pro na podstawie Art.172 ust 1 Ustawy Prawo telekomunikacyjne." />
            <Box display={'flex'} justifyContent={"right"}>
                <Button
                    type="submit"
                    color='secondary'
                    variant="contained"
                    sx={{ mt: 3, mb: 2, textAlign: "right" }}
                >
                    Zapisz
                </Button>
            </Box>
        </ValidatorForm>
    )
}

export default EditUser